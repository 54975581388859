import React from "react";

function Footer(){
    return (
        <footer className="AppFooter">

        </footer>

    )
}

export default Footer