import React, {Component} from "react";
import {MenuItems} from "./MenuItems";
import '../css/App.css'





class NavBar extends Component{
    state = { clicked :false }
    render(){
        return(
            <nav className="NavBarItems">
                <div className="navbar-logo"><h1>AOD</h1></div>
                <h1 className="menu-icon"><i className="fab fa-react"></i> </h1>
                <ul className={this.state.clicked ? 'nav-menu active' : 'nav-menu'}>
                    {MenuItems.map((item, index) => {
                        return(
                            <li key={index} className='nav-links'>
                                <a className={item.clName} href={item.url} color="white">
                                    {item.title}
                                </a>
                            </li>
                        )
                    })}
                </ul>
            </nav>
        )
    }
}
export default NavBar
