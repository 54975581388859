export const MenuItems = [
    {
        title: "Home",
        url: '#',
        clName: 'nav-links'
    },
    {
        title: "About",
        url: 'https://anorangedoor.com/about-an-orange-door/',
        clName: 'nav-links'
    },
    {
        title: "Work",
        url: 'https://anorangedoor.com/an-orange-door-portfolio/',
        clName: 'nav-links'
    },
    {
        title: "Contact",
        url: 'https://anorangedoor.com/contact-an-orange-door/',
        clName: 'nav-links'
    },

]
