import React from "react";
import Navigation from "../Interface/NavBar";

function Header01(){
    return (
        <nav>
            <Navigation />
        </nav>
    )
}

export default Header01