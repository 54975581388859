import React from 'react';
import './Util/css/App.css';
import Footer from "./Util/Struct/Foooter_01";
import Header01 from "./Util/Struct/Header01";
import Body from "./Util/Struct/Body_01";


function App() {
  return (
    <div className="App">
        <Header01 />
        <Body />
        <Footer />
    </div>
  );
}
export default App;
