import React from "react";
import '../css/App.css'
import Scene from "../Scenes/Scene_06";

function MasterScene(){
    return (
        <div className="AppBody">
            <Scene />
        </div>
    )
}
export default MasterScene
