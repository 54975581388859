import * as THREE from 'three'
import React, { Suspense, useCallback, useRef, useMemo } from 'react'
import { Canvas, useLoader, useFrame } from 'react-three-fiber'
import Effects from "../Effects/Effects";
//import {GlitchPass} from "../FX/Glitchpass";


/*const makeUrl = (file) => `https://aod-public.s3-us-west-2.amazonaws.com/aod-images/appanorangedoor/images/${file}.jpg`*/

const makeUrl = (file) => `./Util/assets/tex/${file}.jpg`


function Planet() {
    const ref = useRef()
    const [texture, bump ] = useLoader(THREE.TextureLoader, [
        makeUrl('2k_mercury'),
        makeUrl('earth_bump'),
        'http://jaanga.github.io/moon/heightmaps/WAC_GLD100_E000N1800_004P-1024x512.png',
    ])
    useFrame(
        ({ clock }) =>
            (ref.current.rotation.x = ref.current.rotation.y = ref.current.rotation.z =
                Math.cos(clock.getElapsedTime() / 8) * Math.PI)
    )
    return (
        <group ref={ref}>
            <mesh>
                <sphereBufferGeometry args={[12, 64, 64]} />
                <meshPhysicalMaterial map={texture} bumpMap={bump} bumpScale={0.95} reflectivity={0.2} roughness={0.55} metalness={1.0}/>
            </mesh>

        </group>
    )
}

const Box = ({position, args}) =>{
    const mesh = useRef(null);
    //useFrame( () => (mesh.current.rotation.x = mesh.current.rotation.y += 0.01));
    return(
        <mesh position={position} ref={mesh}>
            <boxBufferGeometry attach='geometry' args={args}  />
            <meshPhysicalMaterial attach='material' color="#000000" roughness={0.55} metalness={0.1} />
        </mesh>
    );
};
//Create a sphere
const Sphere = ({position, args}) =>{
    const mesh = useRef(null);
    //useFrame( () => (mesh.current.rotation.x = mesh.current.rotation.y += 0.01));
    return(
        <mesh position={position} ref={mesh}>
            <sphereBufferGeometry attach='geometry' args={args}  />
            <meshPhysicalMaterial attach='material' color="#FF3900" roughness={0.65} metalness={0.1} />
        </mesh>
    );
}

//Create a ground plane
const GroundPlane = ({position, args}) =>{
    const mesh = useRef(null);
    //useFrame( () => (mesh.current.rotation.x = mesh.current.rotation.y += 0.01));
    return(
        <mesh position={position} ref={mesh}>
            <planeBufferGeometry attach='geometry' args={args}  />
            <meshPhysicalMaterial attach='material' color="black" roughness={0.35} metalness={0.00}  />
        </mesh>
    );
}


function Swarm({ count, mouse }) {
    const mesh = useRef()
    const dummy = useMemo(() => new THREE.Object3D(), [])

    const particles = useMemo(() => {
        const temp = []
        for (let i = 0; i < count; i++) {
            const t = Math.random() * 100
            const factor = 20 + Math.random() * 100
            const speed = 0.01 + Math.random() / 200
            const xFactor = -20 + Math.random() * 40
            const yFactor = -20 + Math.random() * 40
            const zFactor = -20 + Math.random() * 40
            temp.push({ t, factor, speed, xFactor, yFactor, zFactor, mx: 0, my: 0 })
        }
        return temp
    }, [count])

    useFrame((state) => {
        particles.forEach((particle, i) => {
            let { t, factor, speed, xFactor, yFactor, zFactor } = particle
            t = particle.t += speed / 2
            const a = Math.cos(t) + Math.sin(t * 1) / 10
            const b = Math.sin(t) + Math.cos(t * 2) / 10
            const s = Math.max(1.5, Math.cos(t) * 5)
            particle.mx += (mouse.current[0] - particle.mx) * 0.02
            particle.my += (-mouse.current[1] - particle.my) * 0.02
            dummy.position.set(
                (particle.mx / 10) * a + xFactor + Math.cos((t / 10) * factor) + (Math.sin(t * 1) * factor) / 10,
                (particle.my / 10) * b + yFactor + Math.sin((t / 10) * factor) + (Math.cos(t * 2) * factor) / 10,
                (particle.my / 10) * b + zFactor + Math.cos((t / 10) * factor) + (Math.sin(t * 3) * factor) / 10
            )
            dummy.scale.set(s, s, s)
            dummy.updateMatrix()
            mesh.current.setMatrixAt(i, dummy.matrix)
        })
        mesh.current.instanceMatrix.needsUpdate = true
    })

    return (
        <>
            <instancedMesh ref={mesh} args={[null, null, count]}>
                <sphereBufferGeometry attach="geometry" args={[1, 64, 64]} />
                <meshPhysicalMaterial attach='material'
                                      color="#000"
                                      roughness={0.65}
                                      metalness={0.2}
                                      emissive={1.0}
                                      emissiveIntensity={1.0}
                                      em
                />
            </instancedMesh>
        </>
    )
}












function Scene() {
    const mouse = useRef([0, 0])
    const onMouseMove = useCallback(({ clientX: x, clientY: y }) => (mouse.current = [x - window.innerWidth / 2, y - window.innerHeight / 2]), [])
    return (
        <div style={{ width: '100%', height: '100%' }} onMouseMove={onMouseMove}>
            <Canvas
                gl={{ alpha: false, antialias: true, logarithmicDepthBuffer: true }}
                camera={{ fov: 60, position: [0, 0, 90] }}

                onCreated={({ gl }) => {
                    gl.setClearColor('black')

                    //Apply LUT
                    gl.toneMapping = THREE.ACESFilmicToneMapping
                    gl.outputEncoding = THREE.sRGBEncoding
                }}>

                <fog attach="fog" args={['#111', 70, 100]} />

                <ambientLight intensity={.1} color="white"/>
                <pointLight position={[100, 100, 100]} intensity={1} color="blue" />
                <pointLight position={[-100, -100, -100]} intensity={1} color="blue" />
                <pointLight position={[30, 30, 70]} intensity={1} color="FF3900" />
                <pointLight position={[0, 200, 200]} intensity={1} color="white" />




                <GroundPlane  position={[0, -1000, -80]} args={[10000,20000]}/>

                <Swarm mouse={mouse} count={150} />
                <Suspense fallback={null}>
                    <Planet />
                    <Effects />
                </Suspense>
            </Canvas>
        </div>
    )
}

export default Scene;
